<template>
  <div class="wikisearch">
    <BackBtn />

    <div class="wrapper mt-xl" v-if="!loading">
      <div v-if="content && content.length">
        <h2 class="title mb-xxs">
          Recherche<br /><span>"{{ term }}"</span>
        </h2>

        <div class="results mb-s">
          <SvgSprite symbol="ui-search" size="16" />
          {{ content.length }} articles trouvés
        </div>

        <div class="cards">
          <router-link
            class="m-xs"
            v-for="item in content"
            :to="{ path: item.url }"
            :key="item.url"
            :href="item.url"
          >
            <Card :data="item" />
          </router-link>
        </div>
      </div>

      <div v-else class="notfound">
        <h4 class="mb-xxs">Oups...</h4>
        <p class="mb-xs">
          0 article correspond à ta recherche dans l'Espace de partage. Essaie
          peut-être avec un autre mot ?
        </p>
        <Search route="WikiSearch" :category="category" :list="list" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { search } from '@/guides/endpoints/wiki'
import BackBtn from '@/components/BackBtn.vue'
import Card from '@/components/inventory/Card.vue'
import Search from '@/components/inventory/Search.vue'

export default defineComponent({
  props: {
    term: {
      type: String,
      default: '',
    },
    category: {
      type: String,
      default: '',
    },
    list: {
      type: String,
      default: '',
    },
  },
  components: {
    BackBtn,
    Card,
    Search,
  },
  setup(props) {
    const content = ref()
    const loading = ref(false)

    const fetchContent = () => {
      loading.value = true

      search({
        term: props.term,
        category: props.category,
        list: props.list,
      }).then(res => {
        if (res?.data?.content?.cards) {
          content.value = res.data.content.cards
        } else {
          content.value = []
        }

        loading.value = false
      })
    }

    fetchContent()

    watch(() => props.term, fetchContent)
    watch(() => props.category, fetchContent)
    watch(() => props.list, fetchContent)

    return {
      content,
      loading,
    }
  },
})
</script>

<style lang="scss" scoped>
.title {
  line-height: 1.3;

  span {
    color: $hippie-blue;
  }
}

.results {
  display: inline-flex;
  align-items: center;
  color: $hippie-blue;
  font-size: 12px;

  svg {
    fill: $hippie-blue;
    margin-right: 5px;
  }
}

.notfound {
  text-align: center;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;

  h4 {
    color: $rock-blue;
  }

  p {
    @include fluid(
      font-size,
      (
        xxs: 14px,
        l: 16px,
      )
    );
  }
}

::v-deep(.notfound input) {
  background: $c-white !important;
}

@include mq(xl) {
  .cards {
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(2, 1fr);

    > * {
      margin: 0;
    }
  }
}
</style>
