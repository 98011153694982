
import { defineComponent, ref, watch } from 'vue'
import { search } from '@/guides/endpoints/wiki'
import BackBtn from '@/components/BackBtn.vue'
import Card from '@/components/inventory/Card.vue'
import Search from '@/components/inventory/Search.vue'

export default defineComponent({
  props: {
    term: {
      type: String,
      default: '',
    },
    category: {
      type: String,
      default: '',
    },
    list: {
      type: String,
      default: '',
    },
  },
  components: {
    BackBtn,
    Card,
    Search,
  },
  setup(props) {
    const content = ref()
    const loading = ref(false)

    const fetchContent = () => {
      loading.value = true

      search({
        term: props.term,
        category: props.category,
        list: props.list,
      }).then(res => {
        if (res?.data?.content?.cards) {
          content.value = res.data.content.cards
        } else {
          content.value = []
        }

        loading.value = false
      })
    }

    fetchContent()

    watch(() => props.term, fetchContent)
    watch(() => props.category, fetchContent)
    watch(() => props.list, fetchContent)

    return {
      content,
      loading,
    }
  },
})
