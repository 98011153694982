import api from '@/guides/api'

export const list = ({
  category,
  list,
}: { category?: string; list?: string } = {}) => {
  let url = '/wiki'

  if (category) {
    url += '/' + category
  }

  if (list) {
    url += '/' + list
  }

  return api.get(url)
}

export const search = ({
  term,
  category,
  list,
  adjectives,
}: {
  term?: string
  category?: string
  list?: string
  adjectives?: string
} = {}) => {
  let url = '/wiki'

  if (category) {
    url += '/' + category
  }

  if (list) {
    url += '/' + list
  }

  if (term) {
    url += `?term=${term}`
  }

  if (adjectives) {
    const array = adjectives.split(',')

    if (array.length > 1) {
      url += `?adjectives[]=${array
        .map(adjective => adjective)
        .join('&adjectives[]=')}`
    } else {
      url += `?adjectives[]=${adjectives}`
    }
  }

  return api.get(url)
}

export const single = (slug: string | string[]) => {
  return api.get(`/wiki-card/${slug}`)
}

// https://les-guides-app-v1-cms.staging.epic-sys.io/api/v1/wiki/qualis

// adjectives[agile]
